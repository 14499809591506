import _constants2 from "./constants";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.inflect = void 0;
var _constants = _constants2;

function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var match = function match(pattern, word, replacements) {
  if (pattern.substring(0, 1) !== "-") {
    // compare if the first byte is ascii `-`
    return pattern.toLocaleLowerCase() === word.toLocaleLowerCase() ? 0 : -1;
  }

  var matches = new RegExp(pattern.substring(1) + "$", "iu").exec(word);

  if (matches) {
    for (var i = matches.length - 1; i > 0; i--) {
      replacements[i - 1] = matches[matches.length - i];
    }

    return word.length - matches[0].length;
  }

  return -1;
};

var breakAccents = function breakAccents(word) {
  var newWord = word;
  var replacementsArr = [["di", "\u010Fi"], ["ti", "\u0165i"], ["ni", "\u0148i"], ["d\u011B", "\u010Fe"], ["t\u011B", "\u0165e"], ["n\u011B", "\u0148e"]];
  replacementsArr.forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        pattern = _ref2[0],
        replacement = _ref2[1];

    newWord = newWord.split(pattern).join(replacement);
  });
  return newWord;
};

var fixAccents = function fixAccents(word) {
  var newWord = word;
  var replacementsArr = [["\u010Fi", "di"], ["\u0165i", "ti"], ["\u0148i", "ni"], ["\u010Fe", "d\u011B"], ["\u0165e", "t\u011B"], ["\u0148e", "n\u011B"]];
  replacementsArr.forEach(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
        pattern = _ref4[0],
        replacement = _ref4[1];

    newWord = newWord.split(pattern).join(replacement);
  });
  return newWord;
};

var inflect = function inflect(_ref5) {
  var word = _ref5.word,
      grammarCase = _ref5.grammarCase,
      _ref5$plural = _ref5.plural,
      plural = _ref5$plural === void 0 ? false : _ref5$plural,
      _ref5$animate = _ref5.animate,
      animate = _ref5$animate === void 0 ? false : _ref5$animate,
      gender = _ref5.gender;
  var originalWord = word;

  if (plural) {
    grammarCase += 7;
  }

  if (grammarCase === 1) {
    return word;
  }

  var firstLetter = word.substring(0, 1);
  var isUpperCase = firstLetter.toLocaleUpperCase() === firstLetter;
  word = breakAccents(word);
  var lowercaseWord = word.toLocaleLowerCase();

  if (!gender) {
    if (_constants.forceM.includes(lowercaseWord)) {
      gender = "m";
    } else if (_constants.forceF.includes(lowercaseWord)) {
      gender = "f";
    } else if (_constants.forceS.includes(lowercaseWord)) {
      gender = "s";
    }
  }

  var exception;
  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = _constants.exceptions[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var e = _step.value;

      if (lowercaseWord === e[0]) {
        exception = e;
        break;
      }
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator["return"] != null) {
        _iterator["return"]();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  var replacements = [];
  var _iteratorNormalCompletion2 = true;
  var _didIteratorError2 = false;
  var _iteratorError2 = undefined;

  try {
    for (var _iterator2 = _constants.patterns[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
      var pattern = _step2.value;

      if (gender && pattern[0] !== gender) {
        continue;
      }

      word = exception ? exception[1] : word;
      var left = match(pattern[1], word, replacements);

      if (left !== -1) {
        var _ret = function () {
          var prefix = word.substring(0, left);

          if (exception && grammarCase === 4) {
            return {
              v: exception[2]
            };
          }

          var postfix = pattern[grammarCase];

          if (postfix === undefined) {
            return {
              v: ""
            };
          }

          replacements.forEach(function (replacement, i) {
            postfix = postfix.replace(new RegExp(String(i), "g"), replacement);
          });
          var posSlash = postfix.indexOf("/");

          if (posSlash !== -1) {
            if (animate) {
              postfix = postfix.substring(posSlash + 1);
            } else {
              postfix = postfix.substring(0, posSlash);
            }
          }

          var result = fixAccents(prefix + postfix);

          if (isUpperCase) {
            result = result.substring(0, 1).toLocaleUpperCase() + result.substring(1);
          }

          return {
            v: result
          };
        }();

        if (_typeof(_ret) === "object") return _ret.v;
      }
    }
  } catch (err) {
    _didIteratorError2 = true;
    _iteratorError2 = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion2 && _iterator2["return"] != null) {
        _iterator2["return"]();
      }
    } finally {
      if (_didIteratorError2) {
        throw _iteratorError2;
      }
    }
  }

  return originalWord;
};

exports.inflect = inflect;
var _default = inflect;
exports["default"] = _default;
export default exports;
export const __esModule = exports.__esModule;
const _inflect = exports.inflect;
export { _inflect as inflect };